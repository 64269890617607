import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';


import { S3Client, HeadObjectCommand } from "@aws-sdk/client-s3"; // ES Modules import

import * as FileSystem from 'expo-file-system';

import * as MediaLibrary from 'expo-media-library';
import * as Sharing from "expo-sharing";
import { Platform } from 'react-native';

export async function iosTransferFile(downloadFileUri) {
  const imageFileExts = ['jpg', 'png', 'gif', 'heic', 'webp', 'bmp'];

  if (imageFileExts.every(x => !downloadedFileUri.endsWith(x))) {
    const UTI = 'public.item';
    await Sharing.shareAsync(downloadedFileUri, { UTI });
  }
}

export async function androidTransferFile(downloadFileUri: string) {
  await Sharing.shareAsync(downloadFileUri);
}

export async function downloadAttachment(articleTitle, attachmentUrl, num) {
  const name = (articleTitle) + "-attachment" + num;
  if (Platform.OS === 'web') {

    fetch(attachmentUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
  else {
    const callback = (downloadProgress: any) => {
      const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
      console.log(progress);
    };

    fetch(attachmentUrl, { method: 'HEAD' })
      .then(async (res) => {

        const contentType = res.headers.get('content-type');
        const contentLength = res.headers.get('content-length');
        const lastModified = res.headers.get('last-modified');
        const contentSuffix = contentType?.split("/")[1];
        console.log(contentType);

        const downloadResumable = FileSystem.createDownloadResumable(
          attachmentUrl,
          FileSystem.documentDirectory + articleTitle + '.' + contentSuffix,
          {},
          callback
        );

        try {
          const { uri } = await downloadResumable.downloadAsync();

          console.log('Finished downloading to ', uri);

          if (Platform.OS === 'ios') {
            iosTransferFile(uri);
          }
          else {
            androidTransferFile(uri);
          }
        } catch (e) {
          console.error(e);
        }
      }
      )
      .catch((err) => {
        console.error(err);
      });
  }
}
