import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import { Button } from 'react-native-elements';
import { createRecord, deleteRecord } from 'ihp-datasync/ihp-datasync';
import { useCurrentUserId } from '../Auth';

interface UserGroupsProps {
  // navigation: ReactElement;
  // userId: UUID;
  userGroups: Array<Message>;
};

const UserGroupDropdown = ({ userGroups }: UserGroupsProps) => {
  const userId = useCurrentUserId();

  // console.log(userGroups);

  //Chage the bindings one access to table is restored.
  const userGroupsDropdownData = userGroups.map(({ id, name }) => ({label:name, value: id }));
  
  const [value, setValue] = useState(null);
  const [isFocus, setIsFocus] = useState(false);

  const renderLabel = () => {
    if (value || isFocus) {
      return (
        <Text style={[styles.label, isFocus && { color: 'blue' }]}>
          Select a Group
        </Text>
      );
    }
    return null;
  };

  const handleSubmit = () => {
    console.log('Selected Group:', value);
    createRecord('users_groups', {"userId": userId, "userGroupId": value});
  };

  return (
    <View style={styles.container}>

      <Text style={styles.h1}>User Profile Groups Select</Text>
      <Text style={styles.p}>Please select a user group you want to recive notification for.</Text>
      <View style={styles.formContainer}>
          {renderLabel()}
          <Dropdown
            style={[styles.dropdown, isFocus && { borderColor: 'blue' }]}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            inputSearchStyle={styles.inputSearchStyle}
            iconStyle={styles.iconStyle}
            data={userGroupsDropdownData}
            search
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder={!isFocus ? 'Select item' : '...'}
            searchPlaceholder="Search..."
            value={value}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={item => {
              setValue(item.value);
              setIsFocus(false);
            }}
          />
          <Button buttonStyle={styles.submit} title="Submit" onPress={handleSubmit} />
      </View>   
    </View>
    
  );
};

export default UserGroupDropdown;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    width: '100%',
  },
  formContainer: {
    backgroundColor: 'white',
    padding: 16,
    width: '100%',
  },
  dropdown: {
    height: 50,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
    width: '100%',
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: 'absolute',
    backgroundColor: 'white',
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  submit: {
    marginTop: 10,
    backgroundColor: '#006152',
  },
  h1: {
    fontSize:16,
    textAlign: 'center',
    width: '100%',
    marginVertical: 15,
  },
  p: {
    textAlign: 'center',
    width: '100%',
    marginBottom: 10,
  },
});
