import React from 'react';
import { View, Text, ImageBackground, Linking } from 'react-native';
import { DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
// import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';


const CustomDrawer = (props) => {

  const openExternalPage = async () => {
    const url = 'https://www.hse.ie/eng/about/who/health-regions/'; // URL of the external page
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      console.log("Don't know how to open URI: " + url);
    }
  };

  const navigation = useNavigation();

  const goToProfile = () => {
    navigation.navigate('Profile');
  };

  return (
    <View style={{flex:1}}>
      <DrawerContentScrollView {...props} contentContainerStyle={{backgroundColor:'#4FA7AF'}}>
        <View style={{height:100, display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ImageBackground source={require('../assets/hse-mid-west-logo-colour-no-services.png')} style={{height:100, width:200, resizeMode:'contain'}}></ImageBackground>
        </View>
        <View style={{backgroundColor:'#fff', paddingTop:10}}>
          <DrawerItemList {...props}/>
          {/* <DrawerItem labelStyle = {{fontSize:15,}} label="About HSE Health Regions" onPress={openExternalPage} /> */}
        </View>
      </DrawerContentScrollView>
      <View style={{height:50, borderTopColor:'#eee', borderTopWidth:1, alignItems:'center', justifyContent:'center'}}>
        <TouchableOpacity onPress={goToProfile} style={{flexDirection:'row'}}>
          <FontAwesome5 name="user" size={14} style={{marginRight:3}}/>
          <Text>User Profile</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default CustomDrawer