import 'expo-dev-client';
import { Text, View } from 'react-native';
import { ReactElement, useMemo } from 'react';

import { query } from 'ihp-datasync';
import { useQuery } from 'ihp-datasync/ihp-datasync-react';

import Messages from '../components/Messages';
import FeaturedMessages from '../components/FeaturedMessages';
import { globalStyles } from '../components/styles';
import { useCurrentUserId } from '../Auth';

//Inbox
interface InboxProps { navigation: ReactElement; };

export function InboxScr({ navigation }: InboxProps) {
  const userId = useCurrentUserId();

  const featuredMessages = useQuery(query("messages").where("featured", true).orderByDesc("createdAt"));

  const usersGroups = useQuery(query("users_groups").filterWhere("userId", userId));


  const messageIds = useQuery(query("messages_user_groups")
    .whereIn("userGroupId", usersGroups?.map(usersGroup => usersGroup.userGroupId) || [null])); //whereIn works well in this case


  const messages = useQuery(query("messages")
    .filterWhere("publishAt", null)
    .filterWhere("draft", false)
    .orderByDesc("createdAt"));

  const filteredMessages = useMemo(() => {
    return messages?.filter(message => (messageIds?.map(artUGR => artUGR.messageId))?.includes(message.id));
  }
    , [messages, messageIds]
  )

  const filteredFeaturedMessages = useMemo(() => {
    return featuredMessages?.filter(message => (messageIds?.map(artUGR => artUGR.messageId))?.includes(message.id));
  }
    , [featuredMessages, messageIds]
  )

  const userReadMessages = useQuery(query("user_read_messages").where("userId", userId));

  const pinnedMessages = useQuery(query("pinned_messages").where("userId", userId));

  return (
    <View style={globalStyles.container}>
      {/* <View style={globalStyles.heading}>
        <Text style={globalStyles.headingTxt}> Updates </Text>
      </View> */}
      {userId && userReadMessages && filteredFeaturedMessages && pinnedMessages && messages
        && <FeaturedMessages userId={userId} navigation={navigation} messages={filteredFeaturedMessages}
          pinnedMessages={pinnedMessages} userReadMessages={userReadMessages}></FeaturedMessages>}
      <View style={{ marginBottom: 5 }}></View>
      {userId && filteredMessages && pinnedMessages && userReadMessages
        && <Messages userId={userId} navigation={navigation} messages={filteredMessages} pinnedMessages={pinnedMessages} userReadMessages={userReadMessages}></Messages>
      }
    </View>
  );
}

export default InboxScr;