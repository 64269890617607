import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

// import HomeScr from '../screens/HomeScr';

const GoHomeIcon = ({ navigation }) => {
  const goToHome = () => {
    // Navigate to the home screen or perform any other action
    navigation.navigate('Home');
  };

  return (
    <View style={{marginRight:10}}>
      <View>
        <TouchableOpacity onPress={goToHome}>
          <FontAwesome5 name="home" size={24} color="white" />
        </TouchableOpacity>
      </View>
    </View>
  );
};
export default GoHomeIcon;