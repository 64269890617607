import React from 'react';
import { View } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';

const ExternalLinkScreen = () => {
  const navigation = useNavigation();

  useFocusEffect(
    React.useCallback(() => {
      const openExternalLink = async () => {
        try {
          // Open an external link automatically when the screen comes into focus
          await WebBrowser.openBrowserAsync('https://about.hse.ie/health-regions/');
          // Navigate back to the home screen after returning from the external page
          navigation.navigate('Home');
        } catch (error) {
          console.error('Error opening external link:', error);
        }
      };

      openExternalLink();

      // Cleanup function (optional)
      return () => {
        // You can perform cleanup here if needed
      };
    }, [navigation]) // Make sure to include navigation in the dependency array to prevent stale closure
  );

  return <View />;
};

export default ExternalLinkScreen;
