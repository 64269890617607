import React, { ReactElement, useEffect, useMemo } from "react";
import { Text, View, StyleSheet } from 'react-native';

import Messages from '../components/Messages';

import { query } from 'ihp-datasync';
import { useQuery } from 'ihp-datasync/ihp-datasync-react';

import { useCurrentUserId } from '../Auth';

interface MessagesProps {
  pinnedMessages: Array<any>;
  navigation: ReactElement;
};

export default function PinnedMessages({ navigation }: PinnedMessagesProps) {
  const userId = useCurrentUserId();
  const userReadMessages = useQuery(query("user_read_messages").filterWhere("userId", userId));
  const pinnedMessagesRecs = useQuery(query("pinned_messages").filterWhere("userId", userId).orderByDesc("createdAt"));
  const messages = useQuery(query("messages")
    .filterWhere("publishAt", null)
    .filterWhere("draft", false)
    .orderByDesc("createdAt"));

  const pinnedMessages = useMemo(() => {
    return messages?.filter(message => (pinnedMessagesRecs?.map(artUGR => artUGR.messageId))?.includes(message.id));
  }
    , [messages, pinnedMessagesRecs]
  )

  if (!userId || pinnedMessages && pinnedMessages.length === 0) {
    return (
      <View>
        <View style={pinnedStyles.nothingToDisplay}>
          <Text style={pinnedStyles.helpCard}>You don't have any pinned messages. </Text>
          <Text style={pinnedStyles.helpCard}>You can pin a message
            by heading over to your inbox and tapping on the message and selecting
            "Pin Message". The pin icon on the right side of your message will turn red.
          </Text>
          <Text style={pinnedStyles.helpCard}>
            This may be a message that you want to hold on to, or that has an attachment or form that you want to fill out later.
          </Text>
          <Text style={pinnedStyles.helpCard}>
            You can unpin a message anytime by tapping the message and
            selecting "Unpin Message".
          </Text>
        </View>
      </View>);
  } else {
    return (
      <View>
        {userId && pinnedMessages && pinnedMessagesRecs && userReadMessages && <Messages userId={userId} navigation={navigation} messages={pinnedMessages}
          pinnedMessages={pinnedMessagesRecs} userReadMessages={userReadMessages}></Messages>}
      </View>
    )
  }
}

const pinnedStyles = StyleSheet.create({
  nothingToDisplay: {
    borderRadius: 10,
    backgroundColor: '#fff',
    margin: 10,
    padding: 20,
    fontSize: 12
  },
  listContainer: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    paddingHorizontal: 4,
    flex: 1
  },
  pinnedContainer: {
    flex: 1,
    paddingLeft: 14,
    paddingRight: 14,
    fontSize: 18
  },
  helpCard: {
    fontSize: 16,
    fontWeight: '400',
    marginBottom: 12
  },
  messageTitle: {
    fontSize: 16
  },
  messageSubject: {
    fontSize: 14
  },
  messageCard: {
    flex: 1,
    margin: 2,
    borderWidth: 3,
    borderRadius: 4,
    padding: 3
  }
});
