import { FontAwesome5 } from '@expo/vector-icons';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { useEffect, useState } from 'react';
import { query } from 'ihp-datasync';
import { useQuery } from 'ihp-datasync/ihp-datasync-react';
import { deleteRecord } from 'ihp-datasync/ihp-datasync';

// interface BurstableBubbleProps {
//   usersGroupsId : UUID;
//   userGroupId: UUID;
// };

const BurstableBubble = ({usersGroupsId, userGroupId}) => {
  
  const [userGroup, setUserGroup] = useState('hello');
  
  const handlePress = () => {
    deleteRecord('users_groups', usersGroupsId);
    console.log('bursted: ', usersGroupsId);
  }

  //check the label name in user_groups table
  const userGroupQ = useQuery(query("user_groups").filterWhere("id", userGroupId)); //"77f75f69-934d-44ff-a385-0dfe8561ea5c"));
  
  useEffect(() => {
    if (userGroupQ !== null && userGroupQ.length > 0) { 
      setUserGroup(userGroupQ[0].name);
    }
  }, [userGroupQ]);

  return (
      <View style={styles.buble}>
        <Text style={styles.bubleName}>{userGroup}</Text>
        <Pressable onPress={handlePress} style={styles.pressable}><FontAwesome5 name="times"/></Pressable>
      </View>
  );
}

const styles = StyleSheet.create({
  buble: {
    flex: 1,
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    alignItems:'center',
    backgroundColor: '#fff',
    borderRadius: 20,
    flexDirection: 'row',
    minWidth: 150,
    maxWidth: 150,
    height: 30,
    margin: 5,
    paddin:0,
  },
  bubleName: {
    width: 100,
    height: 20,
    margin:10,
  },
  pressable: {
    width: 30,
    height: 30,
    backgroundColor: 'yellow',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems:'center',
  },
  input: {
    paddingHorizontal: 10,
  },
  submit: {
    backgroundColor: '#006152',
  },
});

export default BurstableBubble;