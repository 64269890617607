import 'expo-dev-client';
import { Text, View, StyleSheet, Pressable } from 'react-native';
import { ReactElement, useEffect, useState, useMemo } from 'react';

import { query } from 'ihp-datasync';
import { useQuery } from 'ihp-datasync/ihp-datasync-react';

import { useCurrentUserId, triggerAuth, logout, authCompletedCallbackPath } from '../Auth';
import { FontAwesome5 } from '@expo/vector-icons';
import { ihpHost } from '../App';
import { Platform } from 'react-native';

//Inbox
interface InboxProps { navigation: ReactElement; };


interface LogoutButtonProps {
  isLoggedIn: boolean;
  setLoggedIn: Dispatch<boolean>;

}
function LogoutButton({ isLoggedIn, setLoggedIn }: LogoutButtonProps) {

  useEffect(() => {
    if (!isLoggedIn) {
      logout(ihpHost, setLoggedIn);

      // useAuth(ihpHost, isLoggedIn, setLoggedIn);
      if (Platform.OS === 'web' && window.location.href.indexOf(authCompletedCallbackPath) !== -1) {
        // Return to avoid infinite loop, as we're now called from the login popup callback
        return;
      }

      triggerAuth(ihpHost).then((jwt) => { console.log(jwt); setLoggedIn(true) }).catch((err) => { console.log("Trigger Auth Failed"); setLoggedIn(false); });
      
    }
  }, [isLoggedIn])

  return (
    <View style={{ marginTop: 20, borderColor: '#ccc', borderWidth: 1, padding: 10, borderRadius: 5 }}>
      <Pressable onPress={() => setLoggedIn(false)}>
        <Text style={{ fontWeight: '800', color: '#ccc' }}>
          Logout
        </Text>
      </Pressable>
    </View>
  )
}


export function UserProfileScr({ navigation }: InboxProps) {

  const userId = useCurrentUserId();
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isLoggedIn, setLoggedIn] = useState(true);
  const user = useQuery(query("users").where("id", userId));

  useEffect(() => {
    if (user !== null) {
      setUserName(user[0].name);
      setUserEmail(user[0].email);
    }
  }, [user]);

  return (
    <View style={styles.container}>
      <View style={styles.card} >
        <FontAwesome5 style={styles.icon} name="user" size={70} />
        <Text style={styles.h1}>{userName}</Text>
        <Text style={styles.h2}>{userEmail}</Text>
        <LogoutButton isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,

  },
  card: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    borderRadius: 5,
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
  },
  h1: {
    fontSize: 36,
    color: '#006152',
  },
  h2: {
    fontSize: 14,
    color: '#333',
  },
  icon: {
    color: '#006152',
  },
})

export default UserProfileScr;

