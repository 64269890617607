import React, { useState, useEffect, useMemo } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { query } from 'ihp-datasync/ihp-querybuilder';
import { useQuery } from 'ihp-datasync/ihp-datasync-react';
import { globalStyles } from '../components/styles';
import Articles from '../components/Articles';
import { useCurrentUserId } from '../Auth';

interface ArticlesScreenProps {
  route: any;
  navigation: ReactElement;
}

const ArticlesScreen = ({ route, navigation }: ArticlesScreenProps) => {
  const dummyUUID = "00000000-0000-0000-0000-000000000000";
  const [selectedSectionId, setSelectedSectionId] = useState(dummyUUID);

  useEffect(() => {
    if (route.params && route.params.section) {
      setSelectedSectionId(route.params.section);
    } else {
      setSelectedSectionId(dummyUUID);
    }
  }, [route.params]);

  const userId = useCurrentUserId();

  const usersGroupsRecords = useQuery(query("users_groups")
    .where("userId", userId)
    .orderByDesc("createdAt"));

  const articlesUserGroupsRecords = useQuery(query("articles_user_groups")
    .orderByDesc("createdAt"));

  const filteredArticlesUserGroupsRecords = useMemo(() => {
    return articlesUserGroupsRecords?.filter(articlesUserGroupsRecord => (
      usersGroupsRecords?.map(artUGR => artUGR.userGroupId) || []
    ).includes(articlesUserGroupsRecord.userGroupId));
  }, [articlesUserGroupsRecords, usersGroupsRecords]);

  const articles = useQuery(query("articles")
    .filterWhere("draft", false)
    .filterWhere("publishAt", null)
    .orderByDesc("createdAt"));

  const filteredArticles = useMemo(() => {
    return articles?.filter(article => (
      filteredArticlesUserGroupsRecords?.map(artUGR => artUGR.articleId) || []
    ).includes(article.id));
  }, [articles, filteredArticlesUserGroupsRecords]);

  const sections = useQuery(query("article_sections").orderBy("name"));

  const articleSections = useQuery(query("articles_article_sections")
    .where("articleSectionId", selectedSectionId)
    .orderByDesc("updatedAt"));

  const fileAttachments = useQuery(query('file_attachments'));

  const handleSectionChange = (sectionId) => {
    setSelectedSectionId(sectionId);
  };

  const filteredArticlesBySection = selectedSectionId === dummyUUID
    ? filteredArticles
    : filteredArticles?.filter(article => (
        articleSections?.map(aSection => aSection.articleId) || []
      ).includes(article.id));

  return (
    <View style={globalStyles.container}>
      {/* <View style={globalStyles.heading}>
        <Text style={globalStyles.headingTxt}>Articles</Text>
      </View> */}
      <View style={localStyles.container}>
        <Picker
          style={localStyles.picker}
          selectedValue={selectedSectionId}
          onValueChange={handleSectionChange}
        >
          <Picker.Item label="All Sections" value={dummyUUID} />
          {sections?.map((section) => (
            <Picker.Item key={section.id} label={section.name} value={section.id} />
          ))}
        </Picker>
      </View>
      <Articles
        route={route}
        navigation={navigation}
        attachments={fileAttachments}
        articles={filteredArticlesBySection}
        articlesUserGroupsRecords={articlesUserGroupsRecords}
        userGroupsRecords={usersGroupsRecords}
      />
    </View>
  );
};

const localStyles = StyleSheet.create({
  container: {
    margin: 5,
    fontSize: 18,
  },
  picker: {
    borderRadius: 5,
    borderColor: '#ccc',
    padding: 5,
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  selectedOption: {
    fontSize: 16,
    marginTop: 10,
  },
});

export default ArticlesScreen;
