import React, { useState } from "react";
import { Pressable, View, Text, StyleSheet } from "react-native";
import { FontAwesome5 } from '@expo/vector-icons';

interface InternalSupportButtonProps {
  navigation: any;
  label: string;
  icon : string;
  section : string;
}

function InternalSupportButton({ navigation, icon, label, section }: InternalSupportButtonProps) {
  const [result, setResult] = useState(null);

  return (  
    <Pressable onPress={() => navigation.navigate('Articles', {label:label, section: section}) }>        
      <View style={styles.iconContainer}>
        <FontAwesome5 name={icon} size={50} style={styles.icon}/> 
        <Text style={styles.iconButtonLabel}> {label} </Text>
      </View>
    </Pressable>)
}

const styles = StyleSheet.create({
  iconContainer: {
    marginLeft: 20,
    marginRight: 20, 
    marginTop: 60,
    justifyContent: 'center', 
    alignItems: 'center',
  },
  icon: {
    color: '#283c98',
  },
  iconButtonLabel: {
    fontSize: 14,
    marginTop: 5,
    color: '#283c98',
    fontWeight: '700'
  },
});

export default InternalSupportButton;