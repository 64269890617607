import React from "react";
import { useState, useEffect } from 'react';
import { Modal, Pressable, View, Text, StyleSheet, Button } from "react-native";
import { FontAwesome } from '@expo/vector-icons'; 
import { FontAwesome5 } from '@expo/vector-icons';
import MaterialIcons from '@expo/vector-icons/MaterialCommunityIcons'
import { createRecord, deleteRecord } from 'ihp-datasync/ihp-datasync';
import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import { WebView } from 'react-native-webview';
import DropdownComponent from '../components/dropdown';

import { downloadAttachment } from "./download";


interface MessageProps {
  userId : UUID;
  message: Message;
  isMessageVisible: boolean;
  pinnedMessage: boolean;
  onClose : () => void;
};

function createPinnedMessage (userId:UUID, message:Message) {
  createRecord('pinned_messages', {"userId": userId, "messageId": message.id});
}

function unpinMessage (pinnedMessage:Message) {
   deleteRecord('pinned_messages', pinnedMessage.id);
}

export default function Message({ userId, message, isMessageVisible, pinnedMessage, onClose }: MessageProps) {

  const markup = {__html:  message.messageBody}
  return (
    <View>
      <Modal 
        animationType="slide" 
        transparent={true} 
        visible={isMessageVisible} 
      >
      <View style={styles.container}>
        <View style={styles.topRow}></View>
        <View style={styles.inner}>
          <View style={styles.textContainer}>
            
              <Text style={styles.title}>{message.messageTitle} {(message.featured===true) ? (<FontAwesome name="star" size={20} color="orange"/>) : <></>} 
              {pinnedMessage ? (<FontAwesome5 style={styles.pin} name="thumbtack" size={18} color='red' />) : <></>}
              </Text>

              <Text>{message.messageSubject}</Text>
              {(Platform.OS === 'web') ? (
                  <Text style={styles.body}>
                    <div dangerouslySetInnerHTML={markup} />
                  </Text>
                ) : (
              <WebView
                originWhitelist={['*']}
                scalesPageToFit={false}
                style={styles.body}
                source={{ html: message.messageBody }}
              />
              )}
            
          </View>
          <View style={styles.buttonsContainer}>
              {message.attachment ? <Pressable style={styles.buttonAtt} onPress={() => downloadAttachment(message.messageTitle, message.attachment, 1)}>
                  <Text style={styles.buttonTxtAtt}><FontAwesome5  name="paperclip" size={16}/> Attachment </Text>
              </Pressable> : <></>}
              {pinnedMessage ? 
              (<Pressable style={styles.button} onPress={() => unpinMessage(pinnedMessage)}>
                <Text style={styles.buttonTxt}><FontAwesome5  name="thumbtack" size={16}/> Unpin Message </Text>
              </Pressable>)  :
              (<Pressable style={styles.button} onPress={() => createPinnedMessage(userId, message)}>
                <Text style={styles.buttonTxt}><FontAwesome5  name="thumbtack" size={16}/> Pin Message </Text>
              </Pressable>) 
              }
            </View>
        </View>

        <View style={styles.bottom}>
          <Pressable onPress={onClose}>
            <MaterialIcons name="close" color="#fff" size={44}/>
          </Pressable>
        </View>

      </View>  
    </Modal>

  </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
  },
  topRow: {
    backgroundColor: 'transparent',
    width: '100%',
    height: 70,
  },
  inner: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
  },
  textContainer: {
    flex: 1,
    paddingHorizontal: 14,
    overflow: 'scroll',
    backgroundColor: 'white',
    paddingTop: 20,
    paddingBottom: 20,
    // borderBottomColor: '#999',
    // borderStyle: 'solid',
    // borderBottomWidth: 0.5,
    // borderBottomRightRadius: 0

  },
  messageCont: {
    borderStyle:'solid',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'blue',
    padding: 10
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 0,
    height: 46,
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: 0.5,
    borderRadius: 5,
    margin:5,
    backgroundColor:'white',
  },
  title: {
    fontSize: 24,
  },
  body: {
    marginTop: 10,
    fontSize: 16,
  },
  button: {
    borderRadius: 15,
    backgroundColor: '#006152',//'rgb(0, 130, 202)',
    // width: 170,
    marginLeft: 5,
    marginRight: 5,
    paddingHorizontal: 5,
    paddingVertical: 10,
    color: '#fff',
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity:  0.17,
    shadowRadius: 2.54,
    elevation: 3
  },
  buttonAtt: {
    borderRadius: 15,
    borderColor: 'rgb(128, 128, 128)',
    backgroundColor: 'white',
    // width: 170,
    marginLeft: 5,
    marginRight: 5,
    paddingHorizontal: 10,
    paddingVertical: 10,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity:  0.17,
    shadowRadius: 2.54,
    elevation: 3
  },
  buttonDisabled: {
    borderRadius: 15,
    backgroundColor: 'rgb(200, 200, 200)',
    // width: 170,
    marginLeft: 5,
    marginRight: 5,
    paddingHorizontal: 5,
    paddingVertical: 10,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity:  0.17,
    shadowRadius: 2.54,
    elevation: 3
  },
  buttonTxt: {
    color: 'white',
    fontWeight: '700',
    fontSize: 14,
    textAlign: 'center',
  },
  buttonTxtAtt: {
    fontWeight: '400',
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
  },
  bottom: {
    height: 50,
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeRow: {
    width: 44,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
  },
  pin: {
    marginLeft: 5,
  },
});