import { BackgroundImage } from "@rneui/base";
import { StyleSheet } from "react-native";

export const globalStyles = StyleSheet.create({
    heading: {
      margin: 5,
      borderBottomWidth: 1,
      borderRadius: 5,
      borderColor: "#ccc",
      backgroundColor: "#fff",
      padding: 10,
      textAlign: "center",
      fontSize: 18
    },
    headingTxt: {
      backgroundColor: '#fff',
      textAlign: 'center',
      fontSize: 20
    },
    centerText : {
      textAlign : "center", 
      fontSize:16, 
      marginBottom:16, 
      fontWeight:'700'
    },
    dividingLine: { 
      borderStyle: 'none',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#999', 
      marginBottom: 3, 
      marginLeft: 4, 
      marginRight: 4 
    },
    container: { 
      backgroundColor: '#f2f2f2',
      flex: 1
     },
    headerRow: { 
      flexDirection: 'row', 
      justifyContent: 'center',
      marginTop: 8,
      marginBottom: 8
    },
    headerIcon: { 
      paddingRight: 0, 
      paddingTop: 0 
    },
    inboxHeader: {
      padding: 4,
      fontSize: 8,
      fontWeight: '700'
    },
    iconRow: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    iconButton: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconButtonLabel: {
      fontSize: 14,
      marginTop: 5
    },
    artSectionsCont: {
      display:"flex",
      flexDirection: 'row',
      //alignContent: "center"
      justifyContent: "center",
      padding: 5
    },
    artSection: {
      fontSize: 16,
      backgroundColor: "#fff",
      padding: 5,
      borderRadius: 3,
      textAlign: "center",
      marginRight: 5
    }
  });
  
