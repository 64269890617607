import React, { ReactElement } from "react";
import { useState, useEffect } from 'react';
import { Text, View, StyleSheet, Pressable } from 'react-native';
import Message from './Message';
import { FontAwesome } from '@expo/vector-icons';

import { globalStyles as globStyles } from './styles';
import { useCurrentUserId } from "../Auth";

import { createRecord } from 'ihp-datasync';

function modularToggle(index: number, length: number, step: number) {
    index = index + step;
    if (index < 0) {
        index += length
    }
    else if (index >= length) {
        index = index % length
    }
    return index;
}

export default function FeaturedMessages({ navigation, userId, messages, pinnedMessages, userReadMessages }: MessagesProps) {

    const isMessageRead = (item) => {
        if ((userReadMessages.map(readMessage => readMessage.messageId)).includes(item.id)) {
            return true;
        }
        return false;
    }

    if (messages && messages.length === 0) { return (<Text style={globStyles.centerText}> No Featured messages </Text>) }

    const [isMessageVisible, setIsMessageVisible] = useState(null);
    const [featuredIndex, setFeaturedIndex] = useState(0)

    let sliderGo;

    const onMessageClose = () => {

        // setFeaturedIndex(featuredIndex); //when chengend back to existing it stops...
        setIsMessageVisible(null);
    };

    const onShowMessage = (item) => {
        setIsMessageVisible(item.id);
        if (!(isMessageRead(item))) {
            createRecord('user_read_messages', { 'userId': userId, 'messageId': item.id });
        }
        clearInterval(sliderGo);
    };

    const featuredMessage = messages[featuredIndex];
    const pinnedMessage = pinnedMessages?.find(pm => pm.messageId === featuredMessage.id);

    useEffect(() => {
        sliderGo = setInterval(() => {
            setFeaturedIndex(modularToggle(featuredIndex, messages.length, 1))
        }, 3000);
        return () => clearInterval(sliderGo);
    }, [featuredIndex]);

    return (

        <View style={styles.featuredMessageCard} key={featuredMessage.id}>
            <View style={styles.featuredMessageRow}>
                <View style={styles.prevArrowCont}>
                    <Pressable onPress={() => setFeaturedIndex(modularToggle(featuredIndex, messages.length, -1))}>
                        <FontAwesome name="caret-left" size={54} color="#183689" />
                    </Pressable>
                </View>
                <View style={styles.featMessagesCont}>
                    <Pressable onPress={() => onShowMessage(messages[featuredIndex])}>
                        <View>
                            <Text style={styles.messageTitle}>{messages[featuredIndex].messageTitle} </Text>
                        </View>
                        <Text style={styles.messageSubject}>{featuredMessage.messageBody}</Text>
                        {/* <View><Text style={styles.messageCount}>({featuredIndex + 1} of {messages.length})</Text></View> */}
                    </Pressable>
                </View>
                <View style={styles.nextArrowCont}>
                    <Pressable style={styles.nextArrowLink} onPress={() => setFeaturedIndex(modularToggle(featuredIndex, messages.length, 1))}>
                        <FontAwesome style={styles.nextArrow} name="caret-right" size={54} color="#183689" />
                    </Pressable>
                </View>
            </View>
            <Message userId={userId} isMessageVisible={isMessageVisible === featuredMessage.id} pinnedMessage={pinnedMessage} message={featuredMessage} onClose={onMessageClose} />
        </View>);
}

const styles = StyleSheet.create({
    featuredMessageCard: {
        flexDirection: "column",
        margin: 4,
        borderWidth: 1,
        borderRadius: 4,
        // borderColor: "rgb(204, 204, 204)",
        borderColor: '#80B0A9',
        height: 78,
        // backgroundColor:"#8bc146", 
        backgroundColor: "#80B0A9",
    },
    prevArrowCont: {
        paddingTop: 6,
        width: '10%'
    },
    featMessagesCont: {
        width: '80%',
        overflow: 'hidden',
        margin: 0
    },
    nextArrowCont: {
        paddingTop: 6,
        width: '10%',
    },
    nextArrow: {
    },
    nextArrowLink: {
        flexDirection: 'row-reverse',
    },
    messageTitle: {
        fontSize: 19,
        fontWeight: 'bold',
        color: '#fff'
    },
    messageSubject: { fontSize: 16, fontWeight: '500', color: '#fff', height: 40, overflow: 'hidden' },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 32,
        borderRadius: 15,
        backgroundColor: '#006152',//'rgb(0, 130, 202)',
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 2,
        marginBottom: 8
    },
    readMoreButton: {
        color: 'white'
        , fontWeight: '700'
        , fontSize: 16
    },
    featuredMessageRow: {
        flex: 1,
        flexDirection: "row",
        margin: 2,
        padding: 3,

    },
    messageCount: {
        textAlign: 'center',
        fontWeight: '800',
        color: '#fff',
        marginTop: 5,
    }
});
