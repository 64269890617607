import React, { ReactElement, useEffect, useState } from "react";
import { ActivityIndicator, Text, View, StyleSheet, FlatList, Platform, Pressable, ImageBackground } from 'react-native';
import { DateTime } from "luxon";
import { globalStyles as globStyles } from "../components/styles"
import { updateRecord } from 'ihp-datasync';
import Article from '../components/Article';
import { WebView } from 'react-native-webview';

import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FlashList } from "@shopify/flash-list";


// Utility to clear storage in the app.
async function clearAllData() {
  try {
    await AsyncStorage.multiRemove(await AsyncStorage.getAllKeys());
  } catch (error) {
    console.error('Error clearing data:', error);
  }
}

export function formatInboxTimestamp(isoDate: string) {
  return (DateTime.fromISO(isoDate).toFormat('LLL dd, yyyy'))
}

interface ArticlesProps {
  route: any;
  navigation: ReactElement;
  articles: Array<Article> | null;
  attachments: Array<FileAttachment>;
  articlesUserGroupsRecords: Array<any>;
  userGroupsRecords: Array<any>;
};

export default function Articles({ route, navigation, articles, attachments
  , userGroupsRecords
  , articlesUserGroupsRecords }: ArticlesProps) {

  const [isArticleVisible, setIsArticleVisible] = useState(null);

  //increase article read count by 1
  const updateReadCount = async (articleId: UUID, articleReadCount: number) => {
    //create article read key to access in the app storage memory
    var key = "read";
    let articleRead
    key += articleId;

    if (Platform.OS !== 'web') {
      articleRead = await SecureStore.getItemAsync(key);
    }
    else {
      articleRead = await AsyncStorage.getItem(key);;
    }

    //check if there is a value stored already for this key
    if ((articleReadCount != null) && (articleId != null) && (articleRead == undefined)) {
      //if conditions are met increase read number write article count in db and set article as read in the app storage memory
      if (Platform.OS !== 'web') {
        await SecureStore.setItemAsync(key, JSON.stringify(true));
      }
      else {
        await AsyncStorage.setItem(key, JSON.stringify(true));
      }
      const updatedAricleReadCount = articleReadCount + 1;
      updateRecord('articles', articleId, { "read": updatedAricleReadCount });
    }
  }

  if (!articles || !attachments || !articlesUserGroupsRecords) { return (<ActivityIndicator size="large" />); }

  if (articles?.length === 0) { return (<Text style={globStyles.centerText}> No articles. </Text>); }

  const onArticleClose = async (articleId: UUID, articleReadCount: number) => {
    setIsArticleVisible(null);
    updateReadCount(articleId, articleReadCount);
  };

  const onShowArticle = (item: Article) => {
    setIsArticleVisible(item.id);
  };

  const renderArticleItem = ({ item }: Article) => {
    // Only for web version will use webview otherwise.
    // if (!item) { return <></> }
    const markup = { __html: item.body };

    return (
      <View>
        <Pressable style={styles.articleCard} onPress={() => onShowArticle(item)}>
          <View style={styles.leftCol}>
            {(item.pictureUrl != null) ? (
              <ImageBackground source={{ uri: item.pictureUrl }} resizeMode="cover" style={styles.image}></ImageBackground>
            ) : (
              <ImageBackground source={{ uri: 'https://thealmanian.com/wp-content/uploads/2019/01/product_image_thumbnail_placeholder.png' }} resizeMode="cover" style={styles.image}></ImageBackground>
            )
            }
          </View>

          <View style={styles.rightCol}>
            <Text style={styles.articleTitle}>{item.title}</Text>
            <Text style={styles.dateDisplay}>{formatInboxTimestamp(item.createdAt)}</Text>
            {item.trainerSuppliedEmail ? <Text style={styles.dateDisplay}>{item.trainerSuppliedEmail}</Text> : <></>}
            {(Platform.OS === 'web') ?
              <Text style={styles.articleBody}>
                <div dangerouslySetInnerHTML={markup} />
              </Text>
              :
              <WebView
                style={styles.articleBody}
                scalesPageToFit={false}
                originWhitelist={['*']}
                source={{ html: item.body }}
              />
            }
          </View>
        </Pressable>
        <Article isArticleVisible={isArticleVisible === item.id} article={item} onClose={onArticleClose} navigation={navigation} attachments={attachments} />
      </View>
    );
  };

  // FlatList is a PureComponent, so it needs an entirely new array reference as data for it to re-render. 

  return (
    <FlatList
      showsVerticalScrollIndicator={Platform.OS === 'web' ? true : false}
      ListEmptyComponent={EmptyList}
      data={articles}
      extraData={[articles, attachments, articlesUserGroupsRecords, userGroupsRecords]}
      renderItem={renderArticleItem}
      overScrollMode="never"
    />
  );
}

function EmptyList() {
  return <View><Text>This List Is Empty</Text></View>

}

//scroll mode with webview. 
//https://github.com/react-native-webview/react-native-webview/pull/2874
//https://github.com/react-native-webview/react-native-webview/issues/2364

const styles = StyleSheet.create({
  image: {
    flex: 1,
    justifyContent: 'center',
    height: 140,
    width: 140,
  },
  ImageText: {
    color: 'white',
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000c0',
  },
  listContainer: {
    borderTopRightRadius: 100,
    borderTopLeftRadius: 10,
    paddingHorizontal: 4,
    flex: 1,
  },
  articleCard: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'stretch',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    backgroundColor: "#fff",
    padding: 0,
    height: 140,
    overflow: "hidden",
  },
  leftCol: {
    overflow: 'hidden',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    flexBasis: "30%",
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightCol: {
    flexBasis: "70%",
    padding: 10
  },
  icon: {
    fontSize: 10
  },
  rightColIcon: {
    marginLeft: 15
  },
  articleTitle: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  articleBody: {
    fontSize: 14,
    maxHeight: 80,
    overflow: 'hidden'
  },
  dateDisplay: {
    fontSize: 12,
    color: '#888',
    marginTop: 5
  },
  break: {
    flexBasis: "100%",
    height: 0,
  },
});

