import { FontAwesome5, Ionicons } from '@expo/vector-icons';
import 'expo-dev-client';

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import TabNavigator from './components/TabNavigator';
import MainNavigation from './components/Navigation';
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";


import { DrawerContent, createDrawerNavigator } from '@react-navigation/drawer';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import Icon  from 'react-native-vector-icons/Ionicons'

import { Image, Platform, Text, View, ActivityIndicator, StyleSheet } from 'react-native';

import { initIHPBackend } from 'ihp-datasync';
import { useEffect, useState, useRef, useMemo } from 'react';
import { StatusBar } from 'expo-status-bar';

import * as Sentry from 'sentry-expo';

// missing javascript for datasync calls
import "intl";
import "intl/locale-data/jsonp/en";

import { useAuth, useCurrentUserId, logout } from './Auth';

import { registerForPushNotificationsAsync } from './components/Push';

import { updateRecord } from 'ihp-datasync';
import { Pressable } from "react-native";

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as FileSystem from 'expo-file-system';


//const ihpHost = "http://localhost:8000";
export const ihpHost = "https://mwch.devel-ingeniumtc.com";

// Navigation
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    // background: 'red'
  },
};

function LogoTitle() {
  return (
    <View style={{ height: 70, flexDirection: 'row', backgroundColor: 'transparent', padding: 0, }}>
      <Image style={{ width: 140, height: 70, backgroundColor: 'transparent', }} source={require('./assets/hse-mid-west-logo-colour-no-services.png')} />
    </View>
  );
}

const spinnerStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
});

function LogoutButton({ setLoggedIn }: Dispatch) {
  return (
    <View style={{ alignItems: 'flex-end', marginRight: 20, }}>
      <Pressable onPress={() => logout(ihpHost, setLoggedIn)}>
        <Text style={{ fontWeight: '800', color: '#fff' }}>
          Logout
        </Text>
      </Pressable>
    </View>
  )
}

initIHPBackend({ host: ihpHost });

//Token
function dataSyncDeviceToken(userId: UUID, tokenData: any) {
  updateRecord("users", userId, { "deviceToken": tokenData });
}

//See issue around package management: https://github.com/expo/sentry-expo/issues/285
Sentry.init({
  dsn: "https://f804c492a9bc43b2b00e7083727d3baa@o1009338.ingest.sentry.io/4505244038791168",
  enableNative: false,
  enableInExpoDevelopment: true,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});



export default function App() {
  const [isLoggedIn, setLoggedIn] = useState(false)
  
  useAuth(ihpHost, isLoggedIn, setLoggedIn);

  const userId = useCurrentUserId();

  useEffect(() => {
    if (Platform.OS !== 'web') {
      // set device token on backend
      // return { type: Platform.OS, data: devicePushToken };
      registerForPushNotificationsAsync().then(token => {
        let tokenData = token ? token : null
        if (userId) { dataSyncDeviceToken(userId, tokenData); }
      }).catch(error => {
        //        Sentry.Native.captureMessage("Push Notification Device Token failed" + JSON.stringify(error));
        console.log('Something wrong in registerForPushNotification.')
      });
    }
  }, [isLoggedIn, userId])

  if (isLoggedIn) {
    return (
      <NavigationContainer theme={MyTheme} >
        <MainNavigation/>
      </NavigationContainer>
    );
  }
  else {
    return (<View style={[spinnerStyles.container, spinnerStyles.horizontal]}>
      <ActivityIndicator size="large" />
    </View>)
  }
}
