import React, { ReactElement, useCallback, useEffect } from "react";
import { useState } from 'react';
import { Text, View, StyleSheet, FlatList, Platform, Pressable, SafeAreaView } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { FontAwesome5 } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons'; 
import Message from './Message';
import { DateTime } from "luxon";
import { globalStyles as globStyles } from "./styles"

import { createRecord } from 'ihp-datasync';
import "intl";
import "intl/locale-data/jsonp/en";

export function formatInboxTimestamp(isoDate: string) {
  // return (DateTime.fromISO(isoDate).toFormat('LLL dd, yyyy'))
  return (DateTime.fromISO(isoDate).toFormat('LLL dd'))
}

interface MessagesProps {
  navigation: ReactElement;
  userId: UUID;
  messages: Array<Message>;
  pinnedMessages: Array<any>;
  userReadMessages: Array<any>;
};

export default function Messages({ navigation, userId, messages, pinnedMessages, userReadMessages }: MessagesProps) {
  const [isMessageVisible, setIsMessageVisible] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    setIsRefreshing(true);
    setTimeout(() => {
      setIsRefreshing(false);
    }, 1000);
  }, [messages]);

  if (messages.length === 0) { return (<Text style={globStyles.centerText}> No messages.</Text>); }

  const onMessageClose = () => {
    setIsMessageVisible(null);
  };

  const isMessageRead = (item: Message) => {
    if ((userReadMessages.map(readMessage => readMessage.messageId)).includes(item.id)) {
      return true;
    }
    return false;
  }

  const onShowMessage = async (item: Message) => {
    setIsMessageVisible(item.id);
    if (!(isMessageRead(item))) {
      createRecord('user_read_messages', { 'userId': userId, 'messageId': item.id });
    }
  }

  const renderMessageItem = ({ item, index }: Message): JSX.Element => {
    const pinnedMessage = pinnedMessages?.find(pm => pm.messageId === item.id);
    const pinColour = pinnedMessage ? "red" : "#fff";
    
    return (
      <View>
        <Pressable style={styles.messageCard} onPress={() => onShowMessage(item)}>
          <View style={styles.leftCol}>
            {(!isMessageRead(item)) ?
              <Ionicons style={styles.icon} name="mail-outline" size={24} color="#25292e" />
              : <Ionicons style={styles.icon} name="mail-open-outline" size={24} color="#ccc" />
            }
            {(pinnedMessage)?
            <View style={styles.pinCircle}>
              <FontAwesome5 style={styles.rightColIcon} name="thumbtack" size={10} color={pinColour} />
            </View>
            :<View></View>
            }
            
          </View>

          <View style={styles.midCol}>
            {(!isMessageRead(item)) ?
              <View><Text style={styles.messageTitle}>{item.messageTitle}</Text><Text style={styles.messageBody}>{item.messageBody}</Text></View>
              : <View><Text style={styles.messageTitleRead}>{item.messageTitle}</Text><Text style={styles.messageBodyRead}>{item.messageBody}</Text></View>
            } 
          </View>

          <View style={styles.rightCol}>
            <Text style={styles.dateDisplay}>{formatInboxTimestamp(item.createdAt)}</Text>
            {(item.featured===true) ? (<FontAwesome style={{marginTop:5}} name="star" size={20} color="orange"/>) : <></>} 
          </View>
        </Pressable>
        <Message
          userId={userId}
          isMessageVisible={isMessageVisible === item.id}
          pinnedMessage={pinnedMessage}
          message={item}
          onClose={onMessageClose}
        />
      </View>
    );
  };

  return (
      <FlatList
        showsVerticalScrollIndicator={Platform.OS === 'web' ? true : false}
        data={messages}
        extraData={[messages, pinnedMessages, userId]}
        renderItem={renderMessageItem}
        keyExtractor={item => item.id}
      >
      </FlatList>
  );
}

export const styles = StyleSheet.create({
  messageCard: {
    display: 'flex',
    flexDirection: 'row',
    margin: 1,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    backgroundColor: "#fff",
    padding: 10,
    marginHorizontal: 10,
    minHeight: 70
  },
  leftCol: {
    flexBasis: '15%'
  },
  midCol: {
    flexBasis: '75%',
  },
  rightCol: {
    flexBasis: '10%',
    textAlign: 'center',
  },
  listContainer: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    paddingHorizontal: 4,
    flex: 1
  },
  icon: {
    fontSize: 24,
  },
  pinCircle: {
    position: "relative",
    backgroundColor: '#fff',
    borderColor: '#ffc4c4',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 100,
    width:  21,
    height: 21,
    top: -12,
    left: 15,
    transform: [{rotate:'25deg'}],
    padding: 0
  },
  rightColIcon: {
    position: "absolute",
    marginHorizontal: 5,
    marginVertical: 5
  },
  messageTitle: {
    fontSize: 18,
    fontWeight: 'normal',
    color: '#211c2c',
  },
  messageTitleRead: {
    fontSize: 18,
    fontWeight: 'normal',
    color: '#888',
  },
  messageBody: {
    fontSize: 15,
    maxHeight: 20,
    overflow: 'hidden',
    color: '#6d6a77',
    marginTop:0,
    fontWeight:'500'
  },
  messageBodyRead: {
    fontSize: 15,
    maxHeight: 20,
    overflow: 'hidden',
    color: '#888',
    marginTop:0,
    fontWeight:'500'
  },
  dateDisplay: {
    fontSize: 12,
    color: '#888'
  },
});
