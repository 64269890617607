import { View, Text, StyleSheet } from 'react-native';
import BurstableBubble from '../components/BurstableBubble';

// interface ProfileUserGroupsProps {
//   profileUserGroups: Array<Message>;
// };

const ProfileUserGroups = ({profileUserGroups}) => {

  return (
    <View style={styles.container}>
      <View style={styles.groupsContainer}>
        <Text style={styles.h1}>Assigned User Groups</Text>
        <Text style={styles.p}>These are the user groups that you are assigned to. You will be reciving notifications directed to these groups.</Text>
        <View style={styles.bubblesContainer}>
          {profileUserGroups.map(({ id, userGroupId }, index) => (
            <BurstableBubble key={index} userGroupId={userGroupId} usersGroupsId={id} />
          ))}
        </View>
      </View>
    </View> 
  );
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    alignItems:'center',
    width: '100%',
  },
    groupsContainer: {      
      borderRadius: 5,
      flexDirection: 'row',
      width: '100%',
      flexWrap: 'wrap',
    },
      h1: {
        fontSize:16,
        textAlign: 'center',
        width: '100%',
        marginVertical: 15,
      },
      p: {
        textAlign: 'center',
        width: '100%',
        marginBottom: 10,
      },
      bubblesContainer: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        marginBottom: 10,
        paddingBottom: 10,
      },
});

export default ProfileUserGroups;