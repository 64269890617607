import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert } from 'react-native';
import { Input, Button } from 'react-native-elements';
import { ihpHost } from '../App';
import { useCurrentUserId } from '../Auth';

function PasswordForm({ userId }) {
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  // const [passwordSubmitted, setPasswordSubmitted] = useState(false);

  const handlePasswordChange = (text: string) => {
    setPassword(text);
  };

  const handlePasswordRepeatChange = (text: string) => {
    setPasswordRepeat(text);
  };

  const twoButtonAlert = (title: string, message: string) =>
    Alert.alert(title, message, [
      {
        text: 'Cancel',
        onPress: () => clearPasswordFields,
        // style: 'cancel',
      },
      { text: 'OK', onPress: () => clearPasswordFields },
    ]);

  const clearPasswordFields = () => {
    console.log('Clear Password');
    setPassword('');
    setPasswordRepeat('');
  }

  const handleSubmit = async () => {
    console.log('Password Repeat:', passwordRepeat);
    console.log('Password:', password);

    clearPasswordFields();
    if (password !== passwordRepeat) {
      twoButtonAlert('Password', "Passwords don't match.");
    } else if (password == '' && passwordRepeat == '') {
      twoButtonAlert('Password', 'One of the password fields or both are empty.');
    } else {
      submitPassword(password, userId);
    }
  };

  const submitPassword = async (password: string, userId:string) => {


    const formData = new FormData();
    const fetchUrl = ihpHost + '/UpdatePassword?userId=' + encodeURI(userId);
    
    formData.append('password', password);

    const response = await fetch(fetchUrl, {
      headers: { Accept: 'application/json' },
      method: 'POST',
      body: formData
    });

    const passwordUpdateResponse = await response.json();

    twoButtonAlert('Password', 'Password update complete.');

    console.log(passwordUpdateResponse);

    return passwordUpdateResponse;
  }

  return (
    <View style={styles.card}>
      <Input
        style={styles.input}
        placeholder="Password"
        secureTextEntry={true}
        value={password}
        onChangeText={handlePasswordChange}
        leftIcon={{ type: 'fontawsome', name: 'lock', color: 'rgb(223, 130, 52)' }}
      />
      <Input
        style={styles.input}
        placeholder="Repeat Password"
        secureTextEntry={true}
        value={passwordRepeat}
        onChangeText={handlePasswordRepeatChange}
        leftIcon={{ type: 'fontawsome', name: 'lock', color: 'rgb(223, 130, 52)' }}
      />
      <Button buttonStyle={styles.submit} title="Submit" onPress={handleSubmit} />
    </View>
  );
};


const ChangePasswordScr = () => {
  const userId = useCurrentUserId();

  return (
    <View style={styles.container}>
      <PasswordForm userId={userId} />
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  card: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    borderRadius: 5,
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
  },
  input: {
    paddingHorizontal: 10,
    width: 300,
  },
  submit: {
    backgroundColor: '#006152',
  },
})
export default ChangePasswordScr;