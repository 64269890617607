import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { DrawerContent, createDrawerNavigator } from '@react-navigation/drawer';
import CustomDrawer from './CustomDrawer';

import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';

import GoHomeIcon from './GoHomeIcon';

import HomeScr from '../screens/HomeScr';
import InboxScr from '../screens/InboxScr';
import ArticlesScr from '../screens/ArticlesScr';
import PinnedMessagesScr from '../screens/PinnedMessagesScr';
import SupportScr from '../screens/SupportScr';
import AboutScr from '../screens/AboutScr';
import UserProfileScr from '../screens/UserProfileScr';
import ChangePasswordScr from '../screens/ChangePasswordScr';
import ChangeDepartmentScr from '../screens/ChangeDepartmentScr';

const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();

const InboxTabNavigator = () => {
  return (
    <Tab.Navigator screenOptions={{
      headerShown: false,
      headerStyle: {
        backgroundColor: 'rgb(33, 168, 243)',
        height: 90
      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: 'bold',
      },
      tabBarLabelStyle: {
        fontSize: 14,
      },
      tabBarInactiveTintColor: '#000',
      tabBarActiveTintColor: '#000',
      tabBarInactiveBackgroundColor: '#fff',
      tabBarActiveBackgroundColor: '#B3D0CB',//'rgb(212, 240, 255)',
      tabBarStyle: {
        borderTopColor: '#ccc',//'#0083bf',
        borderTopWidth: 1,
        borderStyle: 'solid',
      }
    }}>
      <Tab.Screen name="Inbox" options={InboxIcon} component={InboxScr} />
      <Tab.Screen name="Pinned" options={PinIcon} component={PinnedMessagesScr} initialParams={{ label: 'Pinned Updates' }} />
    </Tab.Navigator>
  );
}

const TabNavigator = () => {
  return (
    <Tab.Navigator screenOptions={{
      headerShown: false,
      headerStyle: {
        backgroundColor: 'rgb(33, 168, 243)',
        height: 90
      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: 'bold',
      },
      tabBarLabelStyle: {
        fontSize: 14
      },
      tabBarInactiveTintColor: '#000',
      tabBarActiveTintColor: '#000',
      tabBarInactiveBackgroundColor: '#fff',
      tabBarActiveBackgroundColor: 'rgb(212, 240, 255)',
      tabBarStyle: {
        borderTopColor: '#0083bf',
        borderTopWidth: 3,
        borderStyle: 'solid',
      }
    }}>
      <Tab.Screen name="Home" options={HomeIcon} component={HomeScr} />
      <Tab.Screen name="Inbox" options={InboxIcon} component={InboxScr} />
      <Tab.Screen name="Pinned" options={PinIcon} component={PinnedMessagesScr} initialParams={{ label: 'Pinned Updates' }} />
      <Tab.Screen name="Articles" options={TextIcon} component={ArticlesScr} />
      <Tab.Screen name="Resources" options={CommsSupportIcon} component={SupportScr} />
    </Tab.Navigator>
  );
}

const ProfileTabNavigator = () => {
  return (
    <Tab.Navigator screenOptions={{
      headerShown: false,
      headerStyle: {
        backgroundColor: 'rgb(33, 168, 243)',
        height: 90
      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: 'bold',
      },
      tabBarLabelStyle: {
        fontSize: 14,
      },
      tabBarInactiveTintColor: '#000',
      tabBarActiveTintColor: '#000',
      tabBarInactiveBackgroundColor: '#fff',
      tabBarActiveBackgroundColor: '#B3D0CB',//'rgb(212, 240, 255)',
      tabBarStyle: {
        borderTopColor: '#ccc',//'#0083bf',
        borderTopWidth: 1,
        borderStyle: 'solid',
      }
    }}>
      <Tab.Screen name="Profile" options={UserIcon} component={UserProfileScr} />
      <Tab.Screen name="Password" options={PasswordIcon} component={ChangePasswordScr} />
      <Tab.Screen name="Department" options={DepartmentIcon} component={ChangeDepartmentScr} initialParams={{ label: 'Pinned Updates' }} />
    </Tab.Navigator>
  );
}

const MainDrawer = () => {
  const navigation = useNavigation();

  return(
    <Drawer.Navigator 
        initialRouteName="Home" 
        screenOptions={
          {headerShown: true,
            headerTitle: "MWCH Comms",
            headerRight: () => ( <GoHomeIcon navigation={navigation} />),
            headerTitleAlign: "center",
            // headerStyle: { backgroundColor: 'rgb(0, 131, 191)'  },
            headerStyle: { backgroundColor: '#4D9086'  },
            headerTitleStyle: {color: '#fff'},
            drawerLabelStyle: {
              fontSize:15,
            },
            // drawerActiveBackgroundColor:'rgb(47, 139, 39)',
            drawerActiveBackgroundColor:'#006152',
            drawerActiveTintColor:'#fff',
            // drawerInactiveTintColor:'#333',
            drawerItemStyle: {
              // margin:2,
            }
          }}

        drawerContent={props => <CustomDrawer  {...props} />}
        >
        <Drawer.Screen name="Home" component={HomeScr} />
        <Drawer.Screen name="Messages" component={InboxTabNavigator} />
        
        <Drawer.Screen
          name="Articles"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: '00000000-0000-0000-0000-000000000000' }}
        />
        <Drawer.Screen name="Staff Resource" component={SupportScr} />
        <Drawer.Screen
          name="Mental Health"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: '56313317-08cb-4fd2-9492-fad71f304d18' }}
        />
        <Drawer.Screen
          name="Primary Care"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: '8712f5e9-e169-45bd-8d9a-24c7b028cada' }}
        />
        <Drawer.Screen
          name="Older Persons"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: '7f52565d-e86b-4f53-9e1e-e774b72f4d84' }}
        />
        <Drawer.Screen
          name="Disabilities"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: 'f779ce5c-5b2f-4a7d-95d3-a958990160b5' }}
        />
        <Drawer.Screen
          name="Health & Welbeing"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: 'ca77268b-7508-47a4-aa0a-bfb62caffae2' }}
        />
        <Drawer.Screen
          name="Comms"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: '02e3cfab-94c9-4006-bb70-1d9fb68355b1' }}
        />
        <Drawer.Screen
          name="Finance"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: '8b84e7f9-2a5b-40bf-860e-011a535cfbf0' }}
        />
        <Drawer.Screen
          name="QSSI"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: 'a1ae2246-3f26-4fae-a999-ecf8310f78f9' }}
        />
        <Drawer.Screen
          name="HR"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: '9012db09-3d4f-4f89-8810-7d0210b3ddef' }}
        />
        <Drawer.Screen
          name="Project Management Office"
          component={ArticlesScr}
          initialParams={{ navigation: navigation, section: '4971f4c5-d583-43d1-be62-ed4e566166fd' }}
        />
        <Drawer.Screen name="About MWCH" component={AboutScr} />
        <Drawer.Screen name="Profile" component={ProfileTabNavigator} />
        {/* <Drawer.Screen name="Logout" component={LogoutButton} /> */}
      </Drawer.Navigator>
  );
}

const MainNavigation = () => {
  
  return (
    <Stack.Navigator>
      <Stack.Screen name="Main" component={MainDrawer} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
}

const HomeIcon = {
  tabBarIcon: () => { return (<FontAwesome5 name="home" size={24} color="#283c98" />); },
  tabBarLabelStyle: { color: '#283c98', fontWeight: '400', fontSize: 14 },
};
const InboxIcon = {
  tabBarIcon: () => { return (<FontAwesome5 name="mail-bulk" size={24} color="#006152" />); },
  tabBarLabelStyle: { color: '#006152', fontWeight: '400', fontSize: 14 },
};
const CommsSupportIcon = {
  tabBarIcon: () => { return (<FontAwesome5 name="hands-helping" size={24} color="#283c98" />); },
  tabBarLabelStyle: { color: '#283c98', fontWeight: '400', fontSize: 14 }
};
const PinIcon = {
  tabBarIcon: () => { return (<FontAwesome5 name="thumbtack" size={24} color="#006152" />); },
  tabBarLabelStyle: { color: '#006152', fontWeight: '400', fontSize: 14 }
};
const TextIcon = {
  tabBarIcon: () => { return (<FontAwesome5 name="newspaper" size={24} color="#283c98" />); },
  tabBarLabelStyle: { color: '#283c98', fontWeight: '400', fontSize: 14 }
};
const UserIcon = {
  tabBarIcon: () => { return (<FontAwesome5 name="user" size={24} color="#006152" />); },
  tabBarLabelStyle: { color: '#006152', fontWeight: '400', fontSize: 14 }
};
const PasswordIcon = {
  tabBarIcon: () => { return (<FontAwesome5 name="lock" size={24} color="#006152" />); },
  tabBarLabelStyle: { color: '#006152', fontWeight: '400', fontSize: 14 }
};
const DepartmentIcon = {
  tabBarIcon: () => { return (<FontAwesome5 name="user-edit" size={24} color="#006152" />); },
  tabBarLabelStyle: { color: '#006152', fontWeight: '400', fontSize: 14 }
};

export default MainNavigation;