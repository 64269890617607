import * as AuthSession from 'expo-auth-session';
import * as SecureStore from 'expo-secure-store';
import * as WebBrowser from 'expo-web-browser';
import { useEffect, useMemo, useState } from 'react';
import { isJwtExpired } from 'jwt-check-expiration';
import jwt_decode from "jwt-decode";
import { Platform } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Fetch from 'expo-fetch';


WebBrowser.maybeCompleteAuthSession();

export const authCompletedCallbackPath = 'authCompletedCallback';

export function useAuth(ihpHost: string, isLoggedIn: boolean, setLoggedIn: Dispatch) {
    useEffect(() => {
        if (Platform.OS === 'web' && window.location.href.indexOf(authCompletedCallbackPath) !== -1) {
            // Return to avoid infinite loop, as we're now called from the login popup callback
            return;
        }

        triggerAuth(ihpHost).then((jwt) => { console.log(jwt); setLoggedIn(true) }).catch((err) => { console.log("Trigger Auth Failed"); setLoggedIn(false); });

    }, [isLoggedIn]);
}

export function useCurrentUserId(): string {
    const jwt = useJWT();
    return useMemo(() => jwt !== null ? jwt_decode(jwt).sub : null
        , [jwt]);
}

function useJWT(): string | null {
    const [jwt, setJWT] = useState<string | null>(null);

    useEffect(() => {
        if (Platform.OS !== 'web') {
            SecureStore.getItemAsync('ihp_jwt').then(jwt => {
                setJWT(jwt);
            }).catch((e) => {
                //Sentry.Native.captureMessage("useJWT:Failed");
                console.log("JWT Error.")
                setJWT(null)
            });
        }
        else {
            AsyncStorage.getItem('ihp_jwt').then(jwt => {
                setJWT(jwt);
            });
        };
    }, [setJWT]);
    return jwt;
}

// clear web token and retrigger Auth cycle.
export async function logout(ihpHost: string, setLoggedIn: Dispatch) {
    const logoutUrl = ihpHost + '/DeleteSession'

    // need to delete session on server/browser as well.
    fetch(logoutUrl, { method: 'DELETE' }).then(() => {
        if (Platform.OS !== 'web') {
            window.localStorage = { getItem: () => null };
            return (SecureStore.deleteItemAsync('ihp_jwt').then(() => { setLoggedIn(false); }));
        }
        else {
            localStorage.removeItem('ihp_jwt');
            return (AsyncStorage.removeItem('ihp_jwt').then(() => { setLoggedIn(false); }))
        }
    }).catch((err) => console.log("Couldn't fetch delete session: ", err))
};


// The auth cycle setDataSyncJWT is monkey patch so that DataSync can access our jwt.
export async function triggerAuth(ihpHost: string): Promise<string | null> {
    if (Platform.OS !== 'web') {
        try {
            const existingJwt = await SecureStore.getItemAsync('ihp_jwt');

            if (existingJwt && !isJwtExpired(existingJwt)) {
                console.log('setting DataSync JWT.')
                setDataSyncJWT(existingJwt);
                return existingJwt;
            }
        }
        catch (e) {
            setDataSyncJWT(null)
            return null;
        }
    }
    else {
        // for web can try github.com/expo/expo/issues/7744
        const existingJwt = await AsyncStorage.getItem('ihp_jwt');

        if (existingJwt && !isJwtExpired(existingJwt)) {
            setDataSyncJWT(existingJwt);
            return existingJwt;
        }
    };

    // uses window.location for web
    // scheme in app.config.js/app.json
    // need to handle dismiss case.
    // Scheme needs to be explicit in redirectUrl for ios.
    const url = AuthSession.makeRedirectUri({ "scheme": "mwchcomms", "path": authCompletedCallbackPath });
    const result = await WebBrowser.openAuthSessionAsync(ihpHost + '/NewSession?redirectBack=' + encodeURIComponent(url), url);

    if (result.type === 'success') {
        const userId = getUrlParameter(result.url, 'userId');
        const accessToken = getUrlParameter(result.url, 'accessToken');

        const jwt = await fetchJWT(ihpHost, userId, accessToken);

        if (Platform.OS !== 'web') {
            await SecureStore.setItemAsync('ihp_jwt', jwt).then(() => setDataSyncJWT(jwt)).catch((err) => { console.log("Failed to set JWT in SecureStore") });
            return jwt;
        }
        else {
            await AsyncStorage.setItem('ihp_jwt', jwt).then(() => setDataSyncJWT(jwt)).catch((err) => { console.log("Failed to set JWT in AsyncStorage") });
            return jwt;
        }
    } else if (result.type === 'dismiss') {
        if (Platform.OS !== 'web') {
            setDataSyncJWT('');
            return null;
        }
        else {
            setDataSyncJWT('');
            return null;
        }
    }
    else {
        console.log('Failed to login', result);
        return null;
    }
}

// Defines window.localStorage on devices in js-land so that DataSync
// can access the jwt.
function setDataSyncJWT(jwt: string | null) {
    if (Platform.OS !== 'web') {
        window.localStorage = {
            getItem: (key) => {
                if (key === 'ihp_jwt') {
                    return jwt;
                }
                else { return '' }
            },
            removeItem: (key) => {
                return null;
            }
        }
    }
    else {
        //In browser we don't monkey patch localStorage
        localStorage.setItem("ihp_jwt", jwt);
    }
};

async function fetchJWT(ihpHost: string, userId: string, accessToken: string) {
    const response = await fetch(ihpHost + '/JWT?userId=' + encodeURIComponent(userId) + '&accessToken=' + encodeURIComponent(accessToken));
    if (!response.ok) {
        throw new Error('Failed to exchange access token for a JWT');
    }
    return response.text();
}

function getUrlParameter(url: string, name: string) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
