import React, { useState } from "react";
import { Pressable, View, Text, StyleSheet } from "react-native";
//import * as Linking from 'expo-linking';
import { FontAwesome5 } from '@expo/vector-icons';
import { globalStyles as globStyles} from '../components/styles'
import * as WebBrowser from 'expo-web-browser';
import InternalSupportButton from "../components/InternalSupportButton";
import SupportButton from "../components/SupportButton";


export function SupportScr({ navigation }) {
    return (
    <View style={{position: 'relative', height:'100%'}}>
      <View style={globStyles.heading}>
        <Text style={globStyles.headingTxt}><FontAwesome5 style={globStyles.headerIcon} name="hands-helping" size={16}  /> Staff Resources</Text>
      </View>
        <View style={styles.iconRow}>
          <SupportButton icon='wpforms' label='HR Forms' url='https://healthservice.hse.ie/staff/benefits-and-services/hr-forms/'></SupportButton>
          <SupportButton icon='school' label='HSE Land' url='https://www.hseland.ie/dash/Account/PreLogin'></SupportButton>
          <SupportButton icon='briefcase' label='Travel' url='https://healthservice.hse.ie/staff/benefits-and-services/travel-allowances/'></SupportButton>
          <SupportButton icon='euro-sign' label='NISRP' url='https://myhseselfservice.hse.ie/'></SupportButton>
          <InternalSupportButton navigation={navigation} icon='book-reader' label='Training' section='87b6f0bd-6d53-4612-ac6b-15949a382a92' ></InternalSupportButton>
          <InternalSupportButton navigation={navigation} icon='thumbs-up' label='Staff Support' section='d0d7571a-56ec-4fb5-b48e-0fb7e1e8e401'></InternalSupportButton>
        </View>
    </View>
    )
}

const styles = StyleSheet.create({
  iconRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
});

//<Pressable style={styles.iconButton} onPress={() => Linking.openURL(url)}>        
//</Pressable>)

export default SupportScr;