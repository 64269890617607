import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import AntDesign from '@expo/vector-icons/AntDesign';
import { FontAwesome5 } from '@expo/vector-icons';
import { downloadAttachment } from "./download";

  interface DropdownProps {
    attachmentList: any,
    article: any
  }

  const DropdownComponent = ({attachmentList, article}: DropdownProps) => {
    const [value, setValue] = useState(null);
    const [isFocus, setIsFocus] = useState(false);

    return (
      <View style={styles.container}>
        <Dropdown
          style={[styles.dropdown, isFocus && { borderColor: 'blue' }]}
          placeholderStyle={styles.placeholderStyle}
          selectedTextStyle={styles.selectedTextStyle}
          iconStyle={styles.iconStyle}
          data={attachmentList}
          maxHeight={300}
          labelField="label"
          valueField="value"
          placeholder={!isFocus ? 'Attachements' : '...'}
          value={value}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          // onChange={item => {
          //   setValue(item.value);
          //   setIsFocus(false);
          // }}
          renderLeftIcon={() => (
            <FontAwesome5 name="paperclip" style={styles.icon} />
          )}
          onChange={e => {
            downloadAttachment(article.title, e.attachment, e.value);
          }}
        />
      </View>
    );
  };

  export default DropdownComponent;

  const styles = StyleSheet.create({
    container: {
      backgroundColor: 'white',
    },
    dropdown: {
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 15,
      paddingHorizontal: 5,
      paddingVertical: 0,
      marginLeft: 5,
      marginRight: 5,
      width: 140,
      backgroundColor: 'white',
      shadowColor: "#000000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity:  0.17,
      shadowRadius: 2.54,
      elevation: 3,
    },
    icon: {
      marginRight: 5,
    },
    label: {
      position: 'absolute',
      backgroundColor: 'white',
      left: 22,
      top: 8,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 14,
    },
    selectedTextStyle: {
      fontSize: 14,
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 14,
    },
  });