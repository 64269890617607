import React, { ReactElement, useState, useEffect } from "react";
import { Modal, Pressable, View, Text, StyleSheet, Alert, Platform } from "react-native";
import { DateTime } from "luxon";
import { FontAwesome } from '@expo/vector-icons';
import MaterialIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { createRecord } from 'ihp-datasync';
import { useCurrentUserId } from '../Auth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';

import { WebView } from 'react-native-webview';
import DropdownComponent from '../components/dropdown';

export function formatInboxTimestamp(isoDate: string) {
  return (DateTime.fromISO(isoDate).toFormat('LLL dd'))
}

// Utility to clear storage in the app.
async function clearAllData() {
  try {
    await AsyncStorage.multiRemove(await AsyncStorage.getAllKeys());
  } catch (error) {
    console.error('Error clearing data:', error);
  }
}
//clearAllData();
interface ArticleProps {
  article: any,
  isArticleVisible: boolean,
  onClose: (a: UUID, b: number) => void,
  navigation: ReactElement,
  attachments: Array<FileAttachment>
};

export default function Article({ navigation, article, attachments, isArticleVisible, onClose }: ArticleProps) {
  //const markup = { __html: article.body };
  const email = article.trainingSupplierEmail;
  const articleId = article.id;
  const articleReadCount = article.read;
  const matchingAttachments = [];
  const userId = useCurrentUserId();

  for (let i = 0; i < attachments.length; i++) {
    if (attachments[i].articleId === articleId) {
      matchingAttachments.push(attachments[i]);
    }
  }

  let attachment1 = null;
  let attachment2 = null;
  let attachment3 = null;

  if (matchingAttachments.length > 0) {
    matchingAttachments.forEach((attachment) => {
      if (attachment.attachment !== null) {
        attachment1 = attachment.attachment;
      }
      if (attachment.attachment2 !== null) {
        attachment2 = attachment.attachment2;
      }
      if (attachment.attachmentVideo !== null) {
        attachment3 = attachment.attachmentVideo;
      }
    });
  }

  let attachmentsExist = false;
  if (attachment1 !== null || attachment2 != null || attachment3 != null) { attachmentsExist = true; }

  async function isUserInterestedWeb(articleId) {
    try {
      const data = await AsyncStorage.getItem(articleId);
      if (data !== null && data !== undefined) {
        const userData = JSON.parse(data);
        return userData;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error retrieving data:', error);
    }
  }
  
  // The program has an issue with secureStore
  async function isUserInterestedNative(articleId) {
    try {
      const data = await SecureStore.getItemAsync(articleId);
      if (data !== null && data !== undefined) {
        const userData = JSON.parse(data);
        return userData;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error retrieving data:', error);
    }
  }

  //handle "I am interested" button enabled/disabled state based on previous presses
  const [buttonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    getButtonDisabledState(articleId);
  }, []);

  const getButtonDisabledState = async (articleId: UUID) => {
    try {
      let isInterested;
      if (Platform.OS !== 'web') {
        isInterested = await isUserInterestedWeb(articleId);
      }
      else {
        //let isInterested = isUserInterestedNative(articleId); //the program has an issue with secureStore uncomment this line and see console
        isInterested = await isUserInterestedWeb(articleId);
      }
      if (isInterested == true) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    } catch (error) {
      console.log('Error retrieving data:', error);
    }
  };
  const createNativeAlert = () =>
    Alert.alert('Success', 'Thank you for registering your interest. We will be in touch soon with more details.', [
      {
        text: 'Cancel',
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      { text: 'OK', onPress: () => console.log('OK Pressed') },
    ]);

  const recordTrainingInterest = async (articleId: UUID, userId: UUID) => {
    setButtonDisabled(true);
    createRecord('article_interests', { articleId: articleId, userId: userId });
    await AsyncStorage.setItem(articleId, JSON.stringify(true));
    if (Platform.OS === 'web') {
      alert('Thank you for registering your interest. We will be in touch soon with more details.');
    } else {
      createNativeAlert();
    }
  }
  const attachmentList = [];

  attachment1 ? attachmentList.push({ label: 'Attachment 1', value: '1', attachment: attachment1 }) : void (0);
  attachment2 ? attachmentList.push({ label: 'Attachment 2', value: '2', attachment: attachment2 }) : void (0);
  attachment3 ? attachmentList.push({ label: 'Attachment 3', value: '3', attachment: attachment3 }) : void (0);

  const createdAt = formatInboxTimestamp(article.createdAt);
  
  const featImg = (article) => {
    if (article.pictureUrl != null) {
      return '<div style="height:160px; background-image:url(' + article.pictureUrl + '); background-repeat: no-repeat; background-size:cover; background-position: center;" ></div>';
    } else {
      return `<div style="height:160px; background-position: center; background-image:url('https://thealmanian.com/wp-content/uploads/2019/01/product_image_thumbnail_placeholder.png'); background-repeat: no-repeat; background-size:cover;" ></div>`;
    }
  }

  const articleContent = featImg(article) + '<h3>' + article.title + '</h3>' + '<p style="font-size:13px; color:grey;">' + createdAt + '</p>' + article.body;
  const markup = { __html: articleContent };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isArticleVisible}
    >
      <View style={styles.container}>

        <View style={styles.inner}>

          <View style={styles.textContainer}>
            {(Platform.OS === 'web') ? (
              <Text style={styles.body}>
                <div dangerouslySetInnerHTML={markup} />
              </Text>
            ) : (
              <WebView
                originWhitelist={['*']}
                scalesPageToFit={false}
                style={styles.webview}
                source={{ html: articleContent }}
              />

            )}
          </View>

          <View style={styles.buttonsContainer}>
            {attachmentsExist ? (<DropdownComponent attachmentList={attachmentList} article={article} />) : (<View></View>)}

            {email ? (
              <Pressable style={buttonDisabled ? styles.buttonDisabled : styles.button} onPress={() => recordTrainingInterest(articleId, userId)} disabled={buttonDisabled}>
                <Text style={styles.buttonTxt}><FontAwesome name="heart" size={16} /> I'm Interested </Text>
              </Pressable>
            ) : (
              <View></View>
            )}
          </View>

        </View>

        <View style={styles.bottom}>
          <Pressable onPress={() => onClose(articleId, articleReadCount)}>
            <MaterialIcons name="close" color="#fff" size={44} />
          </Pressable>
        </View>

      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
  },
  image: {
    height: 160
  },
  inner: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
  },
  textContainer: {
    flex: 1,
    paddingHorizontal: 14,
    overflow: 'scroll',
    backgroundColor: 'white',
  },
  webview: {
    // height:300,
  },
  title: {
    fontSize: 24,
  },
  body: {
    marginTop: 10,
    fontSize: 16,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 0,
    height: 46,
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: 0.5,
    borderRadius: 5,
    margin: 5,
    backgroundColor: 'white',
  },
  button: {
    borderRadius: 15,
    backgroundColor: '#006152',//'rgb(0, 130, 202)',
    marginLeft: 5,
    marginRight: 5,
    paddingHorizontal: 5,
    paddingVertical: 10,
    color: '#fff',
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.17,
    shadowRadius: 2.54,
    elevation: 3
  },
  buttonDisabled: {
    borderRadius: 15,
    backgroundColor: 'rgb(200, 200, 200)',
    marginLeft: 5,
    marginRight: 5,
    paddingHorizontal: 5,
    paddingVertical: 10,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.17,
    shadowRadius: 2.54,
    elevation: 3
  },
  buttonTxt: {
    color: 'white',
    fontWeight: '700',
    fontSize: 14,
    textAlign: 'center',
  },
  bottom: {
    height: 50,
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateDisplay: {
    fontSize: 14,
    color: '#888',
    marginTop: 5
  },
  closeRow: {
    width: 44,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
  }
});
