import 'expo-dev-client';
import {  Pressable, StyleSheet, Text, View, ScrollView } from 'react-native';
import { ReactElement, useEffect, useState, useMemo } from 'react';

import { query } from 'ihp-datasync';
import { useQuery } from 'ihp-datasync/ihp-datasync-react';

import FeaturedMessages from '../components/FeaturedMessages';
import { useCurrentUserId } from '../Auth';
import * as WebBrowser from 'expo-web-browser';
import { FontAwesome5 } from '@expo/vector-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'

//Home
interface HomeProps { navigation: ReactElement; };

export function HomeScr({ navigation }: HomeProps) {
  const userId = useCurrentUserId();

  const featuredMessages = useQuery(query("messages").where("featured", true).orderByDesc("createdAt"));

  const usersGroups = useQuery(query("users_groups").filterWhere("userId", userId));

  const messageIds = useQuery(query("messages_user_groups")
    .whereIn("userGroupId", usersGroups?.map(usersGroup => usersGroup.userGroupId) || [null])); //whereIn works well in this case

  const messages = useQuery(query("messages")
    .filterWhere("publishAt", null)
    .filterWhere("draft", false)
    .orderByDesc("createdAt"));

  const filteredMessages = useMemo(() => {
    return messages?.filter(message => (messageIds?.map(artUGR => artUGR.messageId))?.includes(message.id));
  }
    , [messages, messageIds]
  )

  const filteredFeaturedMessages = useMemo(() => {
    return featuredMessages?.filter(message => (messageIds?.map(artUGR => artUGR.messageId))?.includes(message.id));
  }
    , [featuredMessages, messageIds]
  )

  const userReadMessages = useQuery(query("user_read_messages").where("userId", userId));

  const pinnedMessages = useQuery(query("pinned_messages").where("userId", userId));

  return (
    <View>
      <View style={styles.topContainer}>
        <View style={styles.topEl}>
          {userId && userReadMessages && filteredFeaturedMessages && pinnedMessages && messages
            && <FeaturedMessages style={styles.topEl} userId={userId} navigation={navigation} messages={filteredFeaturedMessages}
              pinnedMessages={pinnedMessages} userReadMessages={userReadMessages}></FeaturedMessages>}
          <View style={{ marginBottom: 5 }}></View>
        </View>
      </View>
      <View style={styles.container}>
        <SelectionButton color='6' icon='wpforms' label='HR FORMS' url='https://healthservice.hse.ie/staff/benefits-and-services/hr-forms/'></SelectionButton>
        <SelectionButton color='2' icon='user' label='NISRP' url='https://myhseselfservice.hse.ie/'></SelectionButton>
        {/* <SelectionButton color='3' icon='school' label='HSE LAND' url='https://www.hseland.ie/dash/Account/Login'></SelectionButton> */}
        <InternalSelectionButton color='3' icon='newspaper' navigation={navigation}  label='ARTICLES' section='00000000-0000-0000-0000-000000000000'></InternalSelectionButton>
        <InternalSelectionButton color='4' icon='euro-sign' navigation={navigation}  label='STAFF OFFERS' section='e0bd93e3-0c66-4129-85a5-4c5ccf9d7639' ></InternalSelectionButton>
        <InternalSelectionButton color='1' icon='book-reader' navigation={navigation} label='Events/Workshops' section='87b6f0bd-6d53-4612-ac6b-15949a382a92' ></InternalSelectionButton>
        <SelectionButton color='8' icon='twitter' label='Social' url='https://twitter.com/commhealthmw'></SelectionButton>
        {/* <SelectionButtonLong color='2' icon='twitter' label='Socials' url='https://x.com'></SelectionButtonLong> */}
      </View>
    </View>
  );
}

interface InternalSelectionButtonProps {
  color: string;
  navigation: any;
  label: string;
  icon : string;
  section : string;
}

function InternalSelectionButton({ color, icon, navigation, label, section }: InternalSelectionButtonProps) {

  const [boxStyle, setBoxStyle] = useState(styles.selectionBox);
  
  useEffect(() => {
    switch (color) {
      case '1':
        setBoxStyle(styles.selectionBoxLBlue);
        break;
      case '2':
        setBoxStyle(styles.selectionBoxBlue);
        break;
      case '3':
        setBoxStyle(styles.selectionBoxLGreen);
        break;
      case '4':
        setBoxStyle(styles.selectionBoxGreen);
        break;
      case '5':
        setBoxStyle(styles.selectionBoxYellow);
        break;
      default:
        setBoxStyle(styles.selectionBox);
    }
  }, [color]);

  return (
    <Pressable style={boxStyle} onPress={() => navigation.navigate('Articles', {label:label, section: section}) }>        
        <View style={styles.label}>
          <FontAwesome5 name={icon} size={50} style={styles.icon}/>
          <Text style={styles.selectionBoxTxt}>{label}</Text>
        </View> 
    </Pressable>)
}

interface SelectionButtonProps {
  color: string;
  icon: string;
  label: string;
  url : string;
}

function SelectionButton({color, icon, label, url }: SelectionButtonProps) {
  const [boxStyle, setBoxStyle] = useState(styles.selectionBox);
  
  useEffect(() => {
    switch (color) {
      case '1':
        setBoxStyle(styles.selectionBoxLBlue);
        break;
      case '2':
        setBoxStyle(styles.selectionBoxBlue);
        break;
      case '3':
        setBoxStyle(styles.selectionBoxLGreen);
        break;
      case '4':
        setBoxStyle(styles.selectionBoxGreen);
        break;
      case '5':
        setBoxStyle(styles.selectionBoxYellow);
        break;
      case '6':
        setBoxStyle(styles.selectionBoxOrange);
        break;
      case '7':
        setBoxStyle(styles.selectionBoxPurple);
        break;
      case '8':
        setBoxStyle(styles.selectionBoxGrey);
        break;
      default:
        setBoxStyle(styles.selectionBox);
    }
  }, [color]);

  const [result, setResult] = useState(null);

  const _handlePressButtonAsync = async () => {
    let result = await WebBrowser.openBrowserAsync(url);
    setResult(result);
  };

  return (  
    <Pressable style={boxStyle} onPress={() => _handlePressButtonAsync() }>
      <View style={styles.label}>
        {(icon == 'twitter')? <FontAwesomeIcon icon={faXTwitter} size={50} style={styles.icon}/> : <FontAwesome5 name={icon} size={50} style={styles.icon}/>}
        <Text style={styles.selectionBoxTxt}> {label} </Text>
      </View> 
    </Pressable>)
}

interface SelectionButtonLongProps {
  color: string;
  icon: string;
  label: string;
  url : string;
}

function SelectionButtonLong({color, icon, label, url }: SelectionButtonLongProps) {
  const [boxStyle, setBoxStyle] = useState(styles.selectionBox);
  
  useEffect(() => {
    switch (color) {
      case '1':
        setBoxStyle(styles.selectionBoxLBlueLong);
        break;
      case '2':
        setBoxStyle(styles.selectionBoxBlueLong);
        break;
      case '3':
        setBoxStyle(styles.selectionBoxLGreenLong);
        break;
      case '4':
        setBoxStyle(styles.selectionBoxGreenLong);
        break;
      case '5':
        setBoxStyle(styles.selectionBoxYellowLong);
        break;
      default:
        setBoxStyle(styles.selectionBoxLong);
    }
  }, [color]);

  const [result, setResult] = useState(null);

  const _handlePressButtonAsync = async () => {
    let result = await WebBrowser.openBrowserAsync(url);
    setResult(result);
  };

  return (  
    <Pressable style={boxStyle} onPress={() => _handlePressButtonAsync() }>
      <View style={styles.label}>
        <FontAwesome5 name={icon} size={50} style={styles.icon}/>
        <Text style={styles.selectionBoxTxt}> {label} </Text>
      </View> 
    </Pressable>)
}

const styles = StyleSheet.create({
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    rowGap: 10,
    columnGap: 20,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 130,
    backgroundColor: '#80B0A9', //'#8bc146',//'#94c83d',
  },
  topEl: {
    width: '100%',
    fontSize: 24,
    color: "#fff",
    fontWeight: '900',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    rowGap: 0,
    columnGap: 0,
    // overflow: scroll,
  },
  selectionBox: {
    display: 'flex',
    width: '46%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center', 
    backgroundColor: '#fff',
  },
  selectionBoxLGreen: {
    display: 'flex',
    width: '46%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#4FA7AF',//'#94c83d',
  },
  selectionBoxGreen: {
    display: 'flex',
    width: '46%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#006152',//'#2f8b27',
  },
  selectionBoxLBlue: {
    display: 'flex',
    width: '46%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1FAFE1',//'#0083bf',
  },
  selectionBoxBlue: {
    display: 'flex',
    width: '46%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0048A8',//'#283c98',
  },
  selectionBoxYellow: {
    display: 'flex',
    width: '46%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#CBD03A',//#eece07',
  },
  selectionBoxOrange: {
    display: 'flex',
    width: '46%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#DF8234',//#eece07',
  },
  selectionBoxPurple: {
    display: 'flex',
    width: '46%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#5F3DC4',//#eece07',
  },
  selectionBoxGrey: {
    display: 'flex',
    width: '46%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#9BAAB3',//#eece07',
  },

  selectionBoxLGreenLong: {
    display: 'flex',
    width: '93%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#4FA7AF',//'#94c83d',
  },
  selectionBoxGreenLong: {
    display: 'flex',
    width: '93%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#006152',//'#2f8b27',
  },
  selectionBoxLBlueLong: {
    display: 'flex',
    width: '93%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1FAFE1',//'#0083bf',
  },
  selectionBoxBlueLong: {
    display: 'flex',
    width: '93%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0048A8',//'#283c98',
  },
  selectionBoxYellowLong: {
    display: 'flex',
    width: '93%',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#CBD03A',//#eece07',
  },
  selectionBoxTxt: {
    fontSize: 18,
    color: "#fff",
    fontWeight: '700',
  },
  label:{
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: '#fff',
    marginBottom: 12,
  },
  image: {
    flex: 1,
    justifyContent: 'center',
    height: 140,
    width: 140,
  },

  articleCard: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'stretch',
    margin: 5,
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
    backgroundColor: "#fff",
    padding: 0,
    height: 140,
    overflow: "hidden",
  },
  

});

export default HomeScr;