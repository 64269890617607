import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';

import ProfileUserGroups from '../components/ProfileUserGroups';
import UserGroupDropdown from '../components/UserGroupDropdown';

import { query } from 'ihp-datasync';
import { useQuery } from 'ihp-datasync/ihp-datasync-react';
import { useCurrentUserId } from '../Auth';

// import { DataSubscription, createRecord, updateRecord, deleteRecord, createRecords } from 'ihp-datasync/ihp-datasync';
// import { query } from 'ihp-datasync/ihp-querybuilder';
// import { useQuery } from 'ihp-datasync/ihp-datasync-react';

const ChangePasswordScr = () => {
  const userId = useCurrentUserId();
  const [allUserGroups, setAllUserGroups] = useState([]);
  const [profileUsersGroups, setprofileUsersGroups] = useState([]);
  
  const allUserGroupsQ = useQuery(query("user_groups").filterWhere("defaultGroup", true));
  
  const profileUsersGroupsQ = useQuery(query("users_groups").filterWhere("userId", userId));

  useEffect(() => {
    if (allUserGroupsQ !== null) { 
      setAllUserGroups(allUserGroupsQ); 
    }
  }, [allUserGroupsQ]);

  useEffect(() => {
    if (profileUsersGroupsQ !== null) { 
      setprofileUsersGroups(profileUsersGroupsQ); 
    }
  }, [profileUsersGroupsQ]);
  
  return (
    <ScrollView>
      <View style={styles.container}>
        <UserGroupDropdown userGroups={allUserGroups}/>
        <ProfileUserGroups profileUserGroups={profileUsersGroups}/>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    justifyContent: 'center',
    alignItems:'center',
    padding: 10,

  },
  card: {
    flex:1,
    justifyContent: 'center',
    alignItems:'center',
    backgroundColor: '#fff',
    width:'100%',
    height:'100%',
    borderRadius: 10,
  },
  input: {
    paddingHorizontal: 10,
  },
  submit: {
    backgroundColor: '#006152',
  },
})
export default ChangePasswordScr;